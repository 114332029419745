
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StepList from "@/components/scope/step-list.vue";
import {
  emptyToLine,
  splitThousands,
  numDateToDate,
  genderFormat,
  enterWeComFormat, downloadAppFormat, followOfficialAccountFormat, timeFormat,
  dateFormat,
  addOneYearToDate
} from "@/utils/common";
import dayjs from "dayjs";
import { Table } from "@/utils/decorator";
import Template from "@/views/dashboard/template.vue";
import FileUpload from "@/components/scope/file-upload.vue";
import pdf from "vue-pdf/src/vuePdfNoSss.vue";
const insurance = namespace("insurance");

@Component({
  methods: { timeFormat },
  components: { pdf, FileUpload, Template, StepList }
})
@Table("loadListData")
export default class CustomerView extends Vue {
  dayjs = dayjs;
  @insurance.Action getBxInquiryDetail;
  @insurance.Action confirmPayment;
  @insurance.Action editVehicleLicenseInfo;
  @insurance.Action editOwnerInfo;
  @insurance.Action editNonOwnerInfo;
  @insurance.Action inquiryRejected;
  @insurance.Action getGivePackages;
  @insurance.Action agree;
  @insurance.Action paymentLink;
  @insurance.Action getLoggerList;
  @insurance.Action returnElectronicPolicy;
  @insurance.Action quotationReturn;
  @insurance.Action editQualityCertInfo;
  splitThousands = splitThousands;
  numDateToDate = numDateToDate;
  emptyToLine = emptyToLine;
  genderFormat = genderFormat;
  enterWeComFormat = enterWeComFormat;
  downloadAppFormat = downloadAppFormat;
  followOfficialAccountFormat = followOfficialAccountFormat;


  tabIndex: any = "1";
  ctaliPlans: any = [];
  commerciaPlans: any = [];
  attachPlans: any = [];
  otherPlans: any = [];
  vehicleVesselTaxPlans: any = [];

  inquiryNo: any = "";
  detail: any = {};
  dialogVehicleLicenseVisible: boolean = false;
  vehicleLicenseForm: any = {};
  dialogOwnerVisible: boolean = false;
  ownerForm: any = {
    identificationName: "",
    certType: 1,
    mobile: "",
    identificationNumber: ""
  };
  dialogNonOwnerVisible: boolean = false;
  nonOwnerForm: any = {
    identificationName: "",
    certType: 1,
    mobile: "",
    identificationNumber: ""
  };
  rejectDialogVisible: boolean = false;
  rejectForm: any = {};

  agreeDialogVisible: boolean = false;

  agreeForm: any = {};
  givePackages: any = [];
  paymentForm: any = {};
  paymentDialogVisible: boolean = false;

  loggers: any = [];
  loggerPageNo: number = 1;
  loggerPageSize: number = 5;
  paymentViewDialogVisible: boolean = false;

  pklDialogVisible: boolean = false;

  ctaliElectronicPolicy: any = {
    effectiveTime: null,
    expireTime: null,
    pkl: ""
  };
  commercialElectronicPolicy: any = {
    effectiveTime: null,
    expireTime: null,
    pkl: ""
  };
  personalAccidentElectronicPolicy: any = {
    effectiveTime: null,
    expireTime: null,
    pkl: ""
  };

  ePolicyDialogVisible: any = false;

  ePolicyForm: any = {
    totalQuotes: null, //总报价金额
    inquiryNo: "",//报价单
    ctaliBeginTime: "",//交强险起保日期,
    ctaliEndTime: "", //交强险终保日期
    commercialBeginTime: "", //商业险起保日期
    commercialEndTime: "",//商业险终保日期
    quoteReceipt: "",//报价单据,
    ctaliTotalPremium: null,//交钱险总保费
    commercialTotalPremium: null//商业险总保费
  };

  dialogQualityCertVisible: boolean = false;

  qualityCertForm:any={
    imageUrl: "",
    vin: "",
    engineNumber: "",
    registrationDate: "",
    licenseCode: "",
    qualityCertCode:"",
    inquiryNo: ""
  };


  created() {
    this.getDetail();
  }

  getDetail() {
    this.inquiryNo = this.$route.params.id;
    this.getBxInquiryDetail(this.inquiryNo).then(data => {
      this.detail = data.data;
      if (this.detail.inquiryPlan) {
        this.ctaliPlans = this.detail.inquiryPlan.ctaliPlans ? this.detail.inquiryPlan.ctaliPlans : [];
        if(this.ctaliPlans.length > 0){
          this.ctaliPlans = this.ctaliPlans.map(item =>{
            if(item.dataSource == 2 && item.coverage == '投保'){
              item.coverage = "";
            }
            return item;
          })
          this.ctaliPlans = this.ctaliPlans.filter(item => item.coverage != '不投保')
        }
        this.commerciaPlans = this.detail.inquiryPlan.commerciaPlans ? this.detail.inquiryPlan.commerciaPlans : [];

        if(this.commerciaPlans.length > 0){
          this.commerciaPlans = this.commerciaPlans.map(item =>{
            if(item.dataSource == 2 && item.coverage == '投保'){
              item.coverage = "";
            }
            return item;
          })
          this.commerciaPlans = this.commerciaPlans.filter(item => item.coverage != '不投保')
        }
        this.attachPlans = this.detail.inquiryPlan.attachPlans ? this.detail.inquiryPlan.attachPlans : [];
        if(this.attachPlans.length > 0){
          this.attachPlans = this.attachPlans.map(item =>{
            if(item.dataSource == 2 && item.coverage == '投保'){
              item.coverage = "";
            }
            return item;
          })
          this.attachPlans = this.attachPlans.filter(item => item.coverage != '不投保')
        }
        this.vehicleVesselTaxPlans = this.detail.inquiryPlan.vehicleVesselTaxPlans ? this.detail.inquiryPlan.vehicleVesselTaxPlans : [];
        if(this.vehicleVesselTaxPlans.length > 0){
          this.vehicleVesselTaxPlans = this.vehicleVesselTaxPlans.map(item =>{
            if(item.dataSource == 2 && item.coverage == '投保'){
              item.coverage = "";
            }
            return item;
          })
          this.vehicleVesselTaxPlans = this.vehicleVesselTaxPlans.filter(item => item.coverage != '不投保')
        }
        this.otherPlans = this.detail.inquiryPlan.otherPlans ? this.detail.inquiryPlan.otherPlans : [];

        this.ePolicyForm.totalQuotes = this.detail.totalQuotes;
        this.ePolicyForm.ctaliBeginTime = this.detail.ctaliBeginTime;
        this.ePolicyForm.ctaliEndTime = this.detail.ctaliEndTime;
        this.ePolicyForm.commercialBeginTime = this.detail.commercialBeginTime;
        this.ePolicyForm.commercialEndTime = this.detail.commercialEndTime;
        this.ePolicyForm.quoteReceipt = this.detail.quoteReceipt;
        this.ePolicyForm.ctaliTotalPremium = this.detail.ctaliPremium;
        this.ePolicyForm.commercialTotalPremium = this.detail.commercialPremium;
      }
    });
  }

  doConfirmPayment() {
    this.confirmPayment({ inquiryNo: this.inquiryNo }).then(data => {
      console.log(data);
      this.$message({
        message: "操作成功",
        type: "success"
      });
      this.getDetail();
    });
  }

  saveOwner() {
    this.editOwnerInfo(this.ownerForm).then(data => {
      console.log(data);
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.dialogOwnerVisible = false;
        this.getDetail();
      }
    });
  }

  statusFilter(status) {
    switch (status) {
      case 0:
        return "提交信息";
      case 1:
        return "待报价";
      case 2:
        return "已报价待同意";
      case 3:
        return "已同意待核验";
      case 4:
        return "已核验待支付";
      case 5:
        return "已支付待出单";
      case 6:
        return "已完成";
      case 7:
        return "已取消";
    }
    return "-";
  }

  businessTypeFilter(val) {
    switch (val) {
      case 1:
        return "新保";
      case 2:
        return "续保";
    }
  }

  energyTypeFilter(val) {
    switch (val) {
      case 1:
        return "燃油";
      case 2:
        return "新能源";
    }
  }

  certTypeFilter(val) {
    switch (val) {
      case 1:
        return "身份证";
    }
    return "-";
  }

  effectiveTimeSelFn(e){
    let time = e.split(' ')
    this.ePolicyForm.ctaliEndTime = dateFormat(addOneYearToDate(e))+ ' ' + time[time.length-1]
    
  }

  commercialBeginTimeTimeSelFn(e){
    let time = e.split(' ')
    this.ePolicyForm.commercialEndTime = dateFormat(addOneYearToDate(e))+ ' ' + time[time.length-1]
  }
  
  effectiveTimeAllFn(e,key){
    let time = e.split(' ')
    this[key].expireTime = dateFormat(addOneYearToDate(e))+ ' ' + time[time.length-1]
  }

  doDialogVehicleLicenseVisible() {
    this.dialogVehicleLicenseVisible = true;
    this.vehicleLicenseForm = {
      ...this.detail.vehicleLicense,
      licensePlate: this.detail.licensePlate,
      inquiryNo: this.detail.inquiryNo
    };
  }

  doDialogQualityCertVisible(){
    this.qualityCertForm.imageUrl = this.detail.qualityCert.imageUrl;
    this.qualityCertForm.qualityCertCode = this.detail.qualityCert.qualityCertCode;
    this.qualityCertForm.inquiryNo = this.detail.inquiryNo;
    if(this.detail.vehicleLicense){
      this.qualityCertForm.vin = this.detail.vehicleLicense.vin;
      this.qualityCertForm.engineNumber = this.detail.vehicleLicense.engineNumber;
      this.qualityCertForm.registrationDate = this.detail.vehicleLicense.registrationDate;
      this.qualityCertForm.licenseCode = this.detail.vehicleLicense.licenseCode;

    }
    this.dialogQualityCertVisible = true;
  }


  saveVehicleLicense() {
    this.editVehicleLicenseInfo(this.vehicleLicenseForm).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.dialogVehicleLicenseVisible = false;
        this.getDetail();
      }
    });
  }

  paymentLinkUploadSuccess(e) {
    this.paymentForm.paymentLink = e;
  }


  doDialogOwnerVisible() {
    this.dialogOwnerVisible = true;
    this.ownerForm = { ...this.detail.owner, inquiryNo: this.detail.inquiryNo };
  }


  doDialogNonOwnerVisible() {
    this.dialogNonOwnerVisible = true;
    this.nonOwnerForm = { ...this.detail.owner, inquiryNo: this.detail.inquiryNo };
  }

  saveNonOwner() {
    this.editNonOwnerInfo(this.nonOwnerForm).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.dialogNonOwnerVisible = false;
        this.getDetail();
      }
    });
  }

  saveQualityCert(){
    this.editQualityCertInfo(this.qualityCertForm).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.dialogQualityCertVisible = false;
        this.getDetail();
      }
    });
  }

  doRejected() {
    this.rejectDialogVisible = true;
    this.rejectForm = {
      inquiryNo: this.inquiryNo,
      rejectReason: ""
    };
  }

  doInquiryRejected() {
    this.inquiryRejected(this.rejectForm).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.rejectDialogVisible = false;
        this.getDetail();
      }
    });
  }

  doAgreeHandle() {
    this.agree(this.agreeForm).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.agreeDialogVisible = false;
        this.getDetail();
      }
    });
  }

  doCustomerAgreeQuote() {
    this.agreeDialogVisible = true;
    this.agreeForm = {
      inquiryNo: this.inquiryNo,
      packageNo: ""
    };
    this.getGivePackages({}).then(data => {
      this.givePackages = data.data;
    });
  }

  doUploadPaymentCode() {
    this.paymentDialogVisible = true;
    this.paymentForm = {
      inquiryNo: this.inquiryNo,
      paymentLink: this.detail.paymentLink
    };
  }

  doPaymentHandle() {
    this.paymentLink(this.paymentForm).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.paymentDialogVisible = false;
        this.getDetail();
      }
    });
  }

  handleClick(e) {
    if (e.name === "3") {
      this.loggerPageNo = 1;
      this.loggers = [];
      this.loadLoggerList();
    }
  }

  moreLogger() {
    this.loggerPageNo++;
    this.loadLoggerList();
  }

  loadLoggerList() {
    this.getLoggerList({
      orderNo: this.inquiryNo,
      pageSize: this.loggerPageSize,
      pageNum: this.loggerPageNo
    }).then(data => {
      if (data.code == 0) {
        this.loggers.push(...data.data.list);
      }
    });
  }

  doPaymentViewDialog() {
    this.paymentViewDialogVisible = true;
  }

  sumPremium(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = "合计";
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index];
      } else {
        sums[index] = "-";
      }
    });
    return sums;
  }

  skuTypeFilter(val) {
    switch (val) {
      case 1:
        return "套餐卡";
      case 2:
        return "优惠券";
    }
    return "-";
  }

  pklDialog() {
    this.pklDialogVisible = true;
  }

  pklHandleClose() {
    this.pklDialogVisible = false;
  }

  ctaliUploadSuccess(e) {
    this.ctaliElectronicPolicy.pkl = e;
  }

  personalAccidentUploadSuccess(e) {
    this.personalAccidentElectronicPolicy.pkl = e;
  }

  commercialUploadSuccess(e) {
    this.commercialElectronicPolicy.pkl = e;
  }

  doPklHandle() {
    var pklForm = {
      inquiryNo: this.inquiryNo,
      ctaliElectronicPolicy: this.ctaliElectronicPolicy,
      personalAccidentElectronicPolicy: this.personalAccidentElectronicPolicy,
      commercialElectronicPolicy: this.commercialElectronicPolicy
    };
    this.returnElectronicPolicy(pklForm).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.pklDialogVisible = false;
        this.getDetail();
      }
    });
  }

  ePolicyClose() {
    this.ePolicyDialogVisible = false;
  }

  ePolicyOpen() {
    if(this.detail.businessType === 1){
      if(!this.detail.vehicleLicense || !this.detail.vehicleLicense.engineNumber || !this.detail.vehicleLicense.vin || !this.detail.vehicleLicense.registrationDate){
        this.$message.error("请先核对车辆合格信息")
        return
      }
    }else if(this.detail.businessType === 2){
      if(!this.detail.vehicleLicense ||
        !this.detail.vehicleLicense.engineNumber ||
        !this.detail.vehicleLicense.vin ||
        !this.detail.vehicleLicense.registrationDate){

        this.$message.error("请先核对车辆行驶证信息")
        return
      }
    }

    this.ePolicyDialogVisible = true;
    this.ePolicyForm.inquiryNo = this.inquiryNo;
  }

  ePolicyUploadSuccess(e) {
    this.ePolicyForm.quoteReceipt = e;
  }

  addOtherPlan() {
    this.otherPlans.push({
      insuranceName: "",
      premium: ""
    });
  }

  doEPolicy() {
    var form = {
      ...this.ePolicyForm,
      ctaliQuoteDetails: this.ctaliPlans,
      commerciaQuoteDetails: this.commerciaPlans,
      vehicleVesselTaxQuoteDetails: this.vehicleVesselTaxPlans,
      attachQuoteDetails: this.attachPlans,
      otherQuoteDetails: this.otherPlans
    };
    this.quotationReturn(form).then(data => {
      if (data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.ePolicyDialogVisible = false;
        this.getDetail();
      }
    });
  }

  removeOtherPlan(i) {
    var newArr = [];
    for (let j = 0; j < this.otherPlans.length; j++) {
      if (j != i) {
        newArr.push(this.otherPlans[j]);
      }
    }
    this.otherPlans = newArr;
    this.$forceUpdate();
  }

  downloadEvt(url) {
    window.open(url, '_self');
  }
}
